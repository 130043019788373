<template>
    <div>
        <ts-page-title
            :title="$t('serviceQuotation.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('serviceQuotation.pageTitle'),
                    href: '/admin/service-quotations'
                },
                {
                    text: $t('create'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <ts-loading-banner :loading="loading">
                    <form class="form-horizontal">
                        <div class="row">
                            <div class="col-md-12">
                                <Info
                                    v-model="model"
                                    :validate="errors"
                                    @loadingDetail="loadingDetail"
                                    class="tw-p-3 tw-rounded"
                                />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <ts-loading-banner :loading="loading_detail">
                                    <Detail
                                        v-model="model"
                                        :validate="errors"
                                        class="tw-pt-6 tw-rounded"
                                    />
                                </ts-loading-banner>
                            </div>
                        </div>
                        <div class="row tw-py-4">
                            <div
                                class="col-md-12 tw-space-x-4 tw-flex tw-justify-end"
                            >
                                <ts-button
                                    @click.prevent="
                                        $router.push({
                                            name: 'service-quotation'
                                        })
                                    "
                                    >{{ $t('cancel') }}</ts-button
                                >
                                <!-- <ts-button
                                    color="primary"
                                    outline
                                    :waiting="waiting"
                                    @click.prevent="saveAddNew"
                                    >{{ $t('saveAddNew') }}</ts-button
                                > -->
                                <ts-button
                                    color="primary"
                                    :waiting="waiting"
                                    @click.prevent="save"
                                    >{{ $t('save') }}</ts-button
                                >
                            </div>
                        </div>
                    </form>
                </ts-loading-banner>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import moment from 'moment'
import Info from './components/quotation-info.vue'
import Detail from './components/item-detail.vue'
import { mapActions, mapState } from 'vuex'
import { debounce } from 'lodash'

export default {
    name: 'quotationCreate',
    components: {
        Detail,
        Info
    },
    data () {
        return {
            errors: new Errors(),
            waiting_new: false,
            waiting: false,
            loading: false,
            loading_detail: false,
            src: null,
            model: {
                quotation_date: moment().format('DD-MM-YYYY HH:mm:ss'),
                service_ticket_id: null,
                service_ticket_info: [],
                service_ticket: []
            }
        }
    },
    created () {
        this.loading = true
        this.fetchResources()
    },
    computed: {
        ...mapState('customerService/serviceQuotation', ['formViewDatas']),
        customerList () {
            return []
        },
        serviceTickets () {
            return this.formViewDatas.serviceTickets
        }
    },
    methods: {
        ...mapActions('customerService/serviceQuotation', [
            'getFormViewModel',
            'store'
        ]),
        loadingDetail (value) {
            this.loading_detail = value
        },
        async fetchResources () {
            this.loading = true
            await this.getFormViewModel({ params: {}, index: undefined })
            this.loading = false
        },

        handleSearchCustomer: debounce(function (value) {
            this.loading = true
            this.getFormViewModel({
                index: 'customer',
                params: {
                    fnName: 'customer',
                    search: value
                }
            }).finally(() => (this.loading = false))
        }, 800),
        saveAddNew () {
            this.waiting = true
            this.show_pdf = true
            this.errors = new Errors()
            this.store(this.model)
                .then(response => {
                    this.src = response.url
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.clearInput()
                })
                .catch(error => {
                    this.$toasted.error(error.message)
                    this.errors = new Errors(error.errors)
                    this.show_pdf = false
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        save () {
            this.waiting = true
            this.errors = new Errors()
            this.$store
                .dispatch('customerService/serviceQuotation/store', this.model)
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.$router.push({ name: 'service-quotation' })
                })
                .catch(error => {
                    this.$toasted.error(error.message)
                    this.errors = new Errors(error.errors)
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        clearInput () {
            this.model.quotation_date = moment().format('DD-MM-YYYY')
            this.model.project_name = null
            this.model.pic = null
            this.model.customer_id = null
            this.model.grand_total = null
            this.model.vat_amount = null
            this.model.credit_term = null
            this.model.valid_until = null
            this.model.total_exclude_vat = null
            this.model.item_list = []
            this.errors = new Errors()
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'SERVICE QUOTATION',
                desc: not.text
            })
        }
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('customerService/serviceQuotation/RESET_STATE')
        next()
    }
}
</script>
