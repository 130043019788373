<template>
    <div>
        <fieldset>
            <div class="text-blue legend">
                {{ $t('serviceQuotation.repairItem') }}
                <Tooltip placement="top">
                    <Icon type="ios-information-circle-outline" size="17" />
                    <div slot="content">
                        <div class="tw-text-xs">
                            {{ $t('serviceQuotation.repairItem') }}
                        </div>
                    </div>
                </Tooltip>
            </div>

            <div
                class="tw-mt-2 overflow-auto tw-max-h-40"
                style="min-height: 400px"
            >
                <table class="tw-w-full tw-mt-2">
                    <thead>
                        <tr>
                            <th
                                class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300"
                                style="width: 30px"
                            >
                                {{ $t('no') }}
                            </th>
                            <th
                                class="tw-uppercase tw-border tw-text-left bg-header-main tw-border-gray-300"
                                style="width: 180px"
                            >
                                {{ $t('serviceQuotation.barcode') }}
                            </th>
                            <th
                                class="tw-uppercase tw-border tw-text-left bg-header-main tw-border-gray-300"
                                style="width: auto"
                            >
                                {{ $t('serviceQuotation.productName') }}
                            </th>
                            <th
                                class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300"
                                style="width: 135px"
                            >
                                {{ $t('serviceQuotation.uom') }}
                            </th>
                            <th
                                class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300"
                                style="width: 135px"
                            >
                                {{ $t('serviceQuotation.qty') }}
                            </th>

                            <th
                                class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300"
                                style="width: 135px"
                            >
                                {{ $t('serviceQuotation.discountType') }}
                            </th>
                            <th
                                class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300"
                                style="width: 135px"
                            >
                                {{ $t('serviceQuotation.discountRate') }}
                            </th>
                            <th
                                class="tw-uppercase tw-border tw-text-center bg-header-main tw-border-gray-300"
                                style="width: 170px"
                            >
                                {{ $t('serviceQuotation.amount') }}
                            </th>
                            <th
                                class="tw-uppercase tw-border tw-text-left bg-header-main tw-border-gray-300"
                                style="width: 195px"
                            >
                                {{ $t('serviceQuotation.remarks') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template
                            v-for="(main, index_main) in model.service_ticket
                                .repair_product_profiles"
                        >
                            <tr
                                :key="index_main"
                                class="tw-border-l tw-border-r tw-border-t"
                            >
                                <td>
                                    <input
                                        type="text"
                                        :value="index_main + 1"
                                        class="tw-py-1 tw-px-2 tw-w-full tw-outline-none tw-text-center"
                                        readonly
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        :value="main.barcode"
                                        class="tw-py-1 tw-px-2 tw-outline-none"
                                        readonly
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        :value="main.product_name_en"
                                        class="tw-py-1 tw-px-2 tw-outline-none"
                                        readonly
                                    />
                                </td>
                                <td>
                                    <input
                                        v-model.number="main.uom_name_en"
                                        class="form-control tw-text-center"
                                        readonly
                                    />
                                </td>
                                <td>
                                    <input
                                        v-model.number="main.quantity"
                                        type="number"
                                        class="form-control tw-text-center"
                                        readonly
                                    />
                                    <div
                                        class="validate"
                                        v-if="
                                            errors.has(
                                                'item_list.' +
                                                    index_main +
                                                    '.quantity'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                'item_list.' +
                                                    index_main +
                                                    '.quantity'
                                            )
                                        }}
                                    </div>
                                </td>

                                <td>
                                    <Select
                                        class="tw-text-center"
                                        v-model="main.discount_type"
                                        @on-change="calculate"
                                    >
                                        <Option value="$">$</Option>
                                        <Option value="%">%</Option>
                                    </Select>
                                </td>
                                <td>
                                    <input
                                        v-model.number="main.discount_rate"
                                        type="text"
                                        class="form-control tw-text-right"
                                        @input="calculate"
                                    />
                                </td>
                                <td>
                                    <input
                                        v-model="main.grand_total"
                                        type="text"
                                        readonly
                                        class="form-control tw-text-right"
                                    />
                                </td>
                                <td>
                                    <input
                                        v-model="main.remarks"
                                        type="text"
                                        class="form-control"
                                    />
                                    <div
                                        class="validate"
                                        v-if="
                                            errors.has(
                                                'item_list.' +
                                                    index_main +
                                                    '.remarks'
                                            )
                                        "
                                    >
                                        {{
                                            errors.first(
                                                'item_list.' +
                                                    index_main +
                                                    '.remarks'
                                            )
                                        }}
                                    </div>
                                </td>
                            </tr>
                            <tr
                                :key="'d' + index_main"
                                class="tw-border-l tw-border-r tw-border-b"
                            >
                                <td
                                    colspan="9"
                                    class="tw-p-2 tw-rounded-sm tw-border-gray-400"
                                >
                                    <div class="tw-mt-1 tw-w-full">
                                        <fieldset>
                                            <div class="text-blue legend">
                                                {{
                                                    $t(
                                                        'serviceQuotation.repairItemDetail'
                                                    )
                                                }}
                                                <Tooltip placement="top">
                                                    <Icon
                                                        type="ios-information-circle-outline"
                                                        size="17"
                                                    />
                                                    <div slot="content">
                                                        <div class="tw-text-xs">
                                                            {{
                                                                $t(
                                                                    'serviceQuotation.repairItemDetail'
                                                                )
                                                            }}
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <div class="tw-w-full tw-mt-3">
                                                <table class="tw-w-full">
                                                    <tr>
                                                        <th
                                                            class="tw-uppercase tw-border tw-text-center bg-header-detail tw-border-gray-300 tw-w-5"
                                                        >
                                                            {{ $t('no') }}
                                                        </th>
                                                        <th
                                                            class="tw-uppercase tw-border tw-text-left bg-header-detail tw-border-gray-300 tw-w-32"
                                                        >
                                                            {{
                                                                $t(
                                                                    'serviceQuotation.barcode'
                                                                )
                                                            }}
                                                        </th>
                                                        <th
                                                            class="tw-uppercase tw-border tw-text-left bg-header-detail tw-border-gray-300 tw-w-64"
                                                        >
                                                            {{
                                                                $t(
                                                                    'serviceQuotation.productName'
                                                                )
                                                            }}
                                                        </th>
                                                        <th
                                                            class="tw-uppercase tw-border tw-text-center bg-header-detail tw-border-gray-300 tw-w-28"
                                                        >
                                                            {{
                                                                $t(
                                                                    'serviceQuotation.unitPrice'
                                                                )
                                                            }}
                                                        </th>
                                                        <th
                                                            class="tw-uppercase tw-border tw-text-center bg-header-detail tw-border-gray-300 tw-w-28"
                                                        >
                                                            {{
                                                                $t(
                                                                    'serviceQuotation.uom'
                                                                )
                                                            }}
                                                        </th>
                                                        <th
                                                            class="tw-uppercase tw-border tw-text-center bg-header-detail tw-border-gray-300 tw-w-28"
                                                        >
                                                            {{
                                                                $t(
                                                                    'serviceQuotation.qty'
                                                                )
                                                            }}
                                                        </th>
                                                        <th
                                                            class="tw-uppercase tw-border tw-text-center bg-header-detail tw-border-gray-300 tw-w-28"
                                                        >
                                                            {{
                                                                $t(
                                                                    'serviceQuotation.discountType'
                                                                )
                                                            }}
                                                        </th>
                                                        <th
                                                            class="tw-uppercase tw-border tw-text-center bg-header-detail tw-border-gray-300 tw-w-28"
                                                        >
                                                            {{
                                                                $t(
                                                                    'serviceQuotation.discountRate'
                                                                )
                                                            }}
                                                        </th>
                                                        <th
                                                            class="tw-uppercase tw-border tw-text-center bg-header-detail tw-border-gray-300 tw-w-36"
                                                        >
                                                            {{
                                                                $t(
                                                                    'serviceQuotation.grandTotal'
                                                                )
                                                            }}
                                                        </th>
                                                        <th
                                                            class="tw-uppercase tw-border tw-text-left bg-header-detail tw-border-gray-300 tw-w-36"
                                                        >
                                                            {{
                                                                $t(
                                                                    'serviceQuotation.remarks'
                                                                )
                                                            }}
                                                        </th>
                                                    </tr>
                                                    <tr
                                                        :key="index_detail"
                                                        v-for="(
                                                            detail, index_detail
                                                        ) in main.repair_product_profile_details"
                                                    >
                                                        <td>
                                                            <input
                                                                type="text"
                                                                :value="
                                                                    index_detail +
                                                                    1
                                                                "
                                                                class="tw-py-1 tw-px-2 tw-w-full tw-outline-none tw-text-center"
                                                                readonly
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                :value="
                                                                    detail.barcode
                                                                "
                                                                class="tw-py-1 tw-px-2 tw-w-full tw-outline-none tw-pl-3"
                                                                readonly
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                :value="
                                                                    detail.product_name_en
                                                                "
                                                                class="tw-py-1 tw-px-2 tw-w-7/12 tw-outline-none"
                                                                readonly
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                v-model.number="
                                                                    detail.sale_price
                                                                "
                                                                class="form-control tw-text-right"
                                                                @input="
                                                                    calculate
                                                                "
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                v-model.number="
                                                                    detail.uom_name_en
                                                                "
                                                                class="form-control tw-text-center"
                                                                readonly
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                v-model.number="
                                                                    detail.quantity
                                                                "
                                                                type="number"
                                                                class="form-control tw-text-center"
                                                                readonly
                                                            />
                                                            <div
                                                                class="validate"
                                                                v-if="
                                                                    errors.has(
                                                                        'item_list.' +
                                                                            index_detail +
                                                                            '.quantity'
                                                                    )
                                                                "
                                                            >
                                                                {{
                                                                    errors.first(
                                                                        'item_list.' +
                                                                            index_detail +
                                                                            '.quantity'
                                                                    )
                                                                }}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <Select
                                                                class="tw-text-center"
                                                                v-model="
                                                                    detail.discount_type
                                                                "
                                                                @on-change="
                                                                    calculate
                                                                "
                                                            >
                                                                <Option
                                                                    value="$"
                                                                >
                                                                    $
                                                                </Option>
                                                                <Option
                                                                    value="%"
                                                                >
                                                                    %
                                                                </Option>
                                                            </Select>
                                                        </td>
                                                        <td>
                                                            <input
                                                                v-model.number="
                                                                    detail.discount_rate
                                                                "
                                                                type="text"
                                                                class="form-control tw-text-right"
                                                                @input="
                                                                    calculate
                                                                "
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                v-model="
                                                                    detail.grand_total
                                                                "
                                                                type="text"
                                                                readonly
                                                                class="form-control tw-text-right"
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                v-model="
                                                                    detail.remarks
                                                                "
                                                                type="text"
                                                                class="form-control"
                                                            />
                                                            <div
                                                                class="validate"
                                                                v-if="
                                                                    errors.has(
                                                                        'item_list.' +
                                                                            index_detail +
                                                                            '.remarks'
                                                                    )
                                                                "
                                                            >
                                                                {{
                                                                    errors.first(
                                                                        'item_list.' +
                                                                            index_detail +
                                                                            '.remarks'
                                                                    )
                                                                }}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </fieldset>
                                    </div>
                                </td>
                            </tr>
                            <tr :key="'f' + index_main">
                                <td colspan="4" class="tw-text-right">
                                    <b>Total :</b>
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        :value="model.service_ticket.quantity"
                                        class="tw-py-1 tw-px-3 tw-w-full tw-outline-none tw-text-center tw-font-bold"
                                        readonly
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        :value="null"
                                        class="tw-py-1 tw-px-4 tw-w-full tw-outline-none tw-font-bold"
                                        readonly
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        :value="
                                            model.service_ticket.discount_amount
                                        "
                                        class="tw-py-1 tw-px-4 tw-w-full tw-outline-none tw-text-right tw-font-bold"
                                        readonly
                                    />
                                </td>
                                <td>
                                    <input
                                        type="text"
                                        :value="
                                            model.service_ticket.grand_total
                                        "
                                        class="tw-py-1 tw-px-3 tw-w-full tw-outline-none tw-text-right tw-font-bold"
                                        readonly
                                    />
                                </td>
                            </tr>
                        </template>

                        <template
                            v-if="
                                model.service_ticket
                                    ? model.service_ticket
                                          .repair_product_profiles
                                        ? model.service_ticket
                                              .repair_product_profiles.length >
                                          0
                                        : false
                                    : false
                            "
                        >
                            <tr>
                                <td colspan="7" class="text-right">
                                    <div
                                        class="tw-border tw-w-32 tw-py-1 tw-px-2 tw-float-right tw-rounded"
                                    >
                                        <b>{{
                                            $t('serviceQuotation.vatRate')
                                        }}</b>
                                    </div>
                                </td>
                                <td class="tw-p-2">
                                    <div
                                        class="tw-border tw-w-full tw-py-1 tw-px-3 tw-text-right tw-rounded"
                                    >
                                        <b>{{
                                            model.service_ticket.vat_rate
                                                ? model.service_ticket.vat_rate
                                                : 0
                                        }}</b>
                                    </div>
                                    <!-- <Input
                                        v-model.number="
                                            model.service_ticket.vat_rate
                                        "
                                        style="width: 100%"
                                        :class="{
                                            'ivu-form-item-error':
                                                errors.has('level_order')
                                        }"
                                        class="tw-text-right tw-font-bold"
                                    >
                                    </Input> -->
                                </td>
                            </tr>
                            <tr>
                                <td colspan="7" class="text-right">
                                    <div
                                        class="tw-border tw-w-32 tw-py-1 tw-px-2 tw-float-right tw-rounded"
                                    >
                                        <b>{{
                                            $t('serviceQuotation.vatAmount')
                                        }}</b>
                                    </div>
                                </td>
                                <td class="tw-p-2">
                                    <div
                                        class="tw-border tw-w-full tw-py-1 tw-px-3 tw-text-right tw-rounded"
                                    >
                                        <b>{{
                                            model.service_ticket.vat_amount
                                                ? model.service_ticket
                                                      .vat_amount
                                                : 0
                                        }}</b>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="7" class="text-right">
                                    <div
                                        class="tw-border tw-w-32 tw-py-1 tw-px-2 tw-float-right tw-rounded"
                                    >
                                        <b>{{
                                            $t(
                                                'serviceQuotation.totalExcludevat'
                                            )
                                        }}</b>
                                    </div>
                                </td>
                                <td class="tw-p-2">
                                    <div
                                        class="tw-border tw-w-full tw-py-1 tw-px-3 tw-text-right tw-rounded"
                                    >
                                        <b>{{
                                            model.service_ticket
                                                .total_after_discount
                                                ? model.service_ticket
                                                      .total_after_discount
                                                : 0
                                        }}</b>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="7" class="text-right">
                                    <div
                                        class="tw-border tw-w-32 tw-py-1 tw-px-2 tw-float-right tw-rounded"
                                    >
                                        <b>{{
                                            $t('serviceQuotation.grandTotal')
                                        }}</b>
                                    </div>
                                </td>
                                <td class="tw-p-2">
                                    <div
                                        class="tw-border tw-w-full tw-py-1 tw-px-3 tw-text-right tw-rounded"
                                    >
                                        <b>{{
                                            model.service_ticket.grand_total
                                                ? model.service_ticket
                                                      .grand_total
                                                : 0
                                        }}</b>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </fieldset>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { Errors } from 'form-backend-validation'
import { isEmpty, round, debounce } from 'lodash'

export default {
    name: 'ItemDetails',
    props: ['value', 'validate'],
    data () {
        return {
            errors: new Errors(),
            show_modal: false,
            model: {}
        }
    },
    computed: {
        ...mapState('customerService/serviceQuotation', ['edit_data']),
        isNotEdit () {
            return this.edit_data.length > 0 || isEmpty(this.edit_data)
        }
    },
    created () {
        this.model = this.value
    },
    methods: {
        calculate () {
            if (
                this.model.service_ticket
                    ? this.model.service_ticket.repair_product_profiles
                        ? this.model.service_ticket.repair_product_profiles
                              .length > 0
                        : false
                    : false
            ) {
                this.model.service_ticket.sub_total = 0
                this.model.service_ticket.grand_total = 0

                this.model.service_ticket.repair_product_profiles.forEach(
                    main => {
                        main.sub_total = 0
                        main.grand_total = 0

                        main.repair_product_profile_details.forEach(detail => {
                            let sub_total = detail.quantity * detail.sale_price

                            detail.sub_total = round(sub_total, 2)

                            detail.discount_amount = round(
                                detail.discount_type == '%'
                                    ? (sub_total * detail.discount_rate) / 100
                                    : detail.discount_rate,
                                2
                            )

                            detail.grand_total = round(
                                sub_total - detail.discount_amount,
                                2
                            )

                            main.sub_total = round(
                                main.sub_total + detail.grand_total,
                                2
                            )
                        })

                        main.discount_amount = round(
                            main.discount_type == '%'
                                ? (main.sub_total * main.discount_rate) / 100
                                : main.discount_rate,
                            2
                        )
                        main.grand_total = round(
                            main.sub_total - main.discount_amount,
                            2
                        )

                        this.model.service_ticket.sub_total = round(
                            this.model.service_ticket.sub_total +
                                main.grand_total,
                            2
                        )
                    }
                )

                this.model.service_ticket.discount_amount = round(
                    this.model.service_ticket.discount_type == '%'
                        ? (this.model.service_ticket.sub_total *
                              this.model.service_ticket.discount_rate) /
                              100
                        : this.model.service_ticket.discount_rate,
                    2
                )
                this.model.service_ticket.total_after_discount = round(
                    this.model.service_ticket.sub_total -
                        this.model.service_ticket.discount_amount,
                    2
                )

                this.model.service_ticket.vat_amount = round(
                    (this.model.service_ticket.total_after_discount *
                        this.model.service_ticket.vat_rate) /
                        100,
                    2
                )

                this.model.service_ticket.grand_total = round(
                    this.model.service_ticket.total_after_discount -
                        this.model.service_ticket.vat_amount,
                    2
                )
            }
        }
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        },
        'model.service_ticket': debounce(function () {
            this.calculate()
        }, 500)
    }
}
</script>
<style scoped>
.legend {
    position: absolute;
    z-index: 200;
    top: 0;
    font-size: 12px;
    line-height: 1;
    margin: -13px 0 0; /* half of font-size */
    background: #fff;
    border: 1px solid #ddd;
    width: auto;
    padding: 5px 14px;
    box-shadow: -1px 4px 27px -7px #bdbdbd;
    border-radius: 3px;
    font-weight: bold;
}
.bg-header-main {
    background: #3990e0;
    color: white;
}
.bg-header-detail {
    background: #f1f1f1;
}
th {
    padding: 4px 8px;
}
td {
    padding: 3px 3px;
}
</style>
