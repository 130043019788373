<template>
    <div class="tw-border tw-p-3 tw-rounded tw-shadow">
        <div class="row">
            <div class="col-md-6 tw-space-y-3">
                <div class="row">
                    <label class="col-md-3 required">{{
                        $t('serviceQuotation.quotationDate')
                    }}</label>
                    <div class="col-md-6">
                        <DatePicker
                            :value="model.quotation_date"
                            type="datetime"
                            style="width: 100%"
                            placeholder="DD-MM-YYYY HH:mm:ss"
                            format="dd-MM-yyyy HH:mm:ss"
                        ></DatePicker>
                        <div
                            class="text-danger"
                            v-if="errors.has('quotation_date')"
                        >
                            {{ errors.first('quotation_date') }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-3 col-form-label">{{
                        $t('serviceQuotation.customer')
                    }}</label>
                    <div class="col-md-6">
                        <input
                            readonly
                            type="text"
                            :value="
                                model.service_ticket_info
                                    ? model.service_ticket_info.customer
                                        ? model.service_ticket_info.customer
                                              .customer_name
                                        : null
                                    : null
                            "
                            class="form-control"
                        />
                        <div class="validate" v-if="errors.has('customer_id')">
                            {{ errors.first('customer_id') }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-3 col-form-label">{{
                        $t('serviceQuotation.servedBy')
                    }}</label>
                    <div class="col-md-6">
                        <input
                            readonly
                            :value="
                                model.service_ticket_info
                                    ? model.service_ticket_info.served_by_name
                                        ? model.service_ticket_info
                                              .served_by_name.employee
                                            ? model.service_ticket_info
                                                  .served_by_name.employee
                                                  .employee_name_en
                                            : null
                                        : null
                                    : null
                            "
                            type="text"
                            class="form-control"
                        />
                        <div class="validate" v-if="errors.has('credit_term')">
                            {{ errors.first('credit_term') }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-3 col-form-label">{{
                        $t('serviceQuotation.repairedBy')
                    }}</label>
                    <div class="col-md-6">
                        <input
                            readonly
                            :value="
                                model.service_ticket_info
                                    ? model.service_ticket_info
                                          .ticket_owner_name
                                        ? model.service_ticket_info
                                              .ticket_owner_name
                                              .employee_name_en
                                        : null
                                    : null
                            "
                            type="text"
                            class="form-control"
                        />
                        <div class="validate" v-if="errors.has('valid_until')">
                            {{ errors.first('valid_until') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 tw-space-y-3">
                <div class="row">
                    <label class="col-md-3 tw-text-xs required">{{
                        $t('serviceQuotation.ticketNo')
                    }}</label>
                    <div class="col-md-6">
                        <Select
                            v-model="model.service_ticket_id"
                            :filter-by-label="true"
                            :placeholder="$t('select')"
                            :clearable="true"
                            filterable
                            @on-change="onChangeServiceTicket"
                            @on-query-change="handleSearchServiceTicket"
                        >
                            <Option
                                v-for="serviceTicket in serviceTickets"
                                :value="serviceTicket.service_ticket_id"
                                :key="serviceTicket.service_ticket_id"
                                :label="serviceTicket.ticket_no"
                            >
                                {{ serviceTicket.ticket_no }}
                            </Option>
                        </Select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('service_ticket_id')"
                        >
                            {{ errors.first('service_ticket_id') }}
                        </div>
                    </div>
                </div>
                <div class="row">
                    <label class="col-md-3 col-form-label">{{
                        $t('serviceQuotation.customerPhone')
                    }}</label>
                    <div class="col-md-6">
                        <input
                            readonly
                            :value="
                                model.service_ticket_info
                                    ? model.service_ticket_info.customer
                                        ? model.service_ticket_info.customer
                                              .contact_number
                                        : null
                                    : null
                            "
                            type="text"
                            class="form-control"
                        />
                        <div class="validate" v-if="errors.has('pic')">
                            {{ errors.first('pic') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapState } from 'vuex'
import { debounce } from 'lodash'

export default {
    name: 'quotationInfo',
    props: ['value', 'validate'],
    data () {
        return {
            errors: new Errors(),
            waiting_new: false,
            waiting: false,
            loading: false,
            model: {},
            is_disabled: false,
            searching: false
        }
    },
    created () {
        this.model = this.value
    },
    computed: {
        ...mapState('customerService/serviceQuotation', ['formViewDatas']),
        customerList () {
            return []
        },
        serviceTickets () {
            return this.formViewDatas.serviceTickets
        }
    },
    methods: {
        ...mapActions('customerService/serviceQuotation', ['getFormViewModel']),
        handleSearchServiceTicket: debounce(function (value) {
            this.loading = true
            this.getFormViewModel({
                index: 'customer',
                params: {
                    fnName: 'customer',
                    search: value
                }
            }).finally(() => (this.loading = false))
        }, 800),
        onChangeServiceTicket (value) {
            this.$emit('loadingDetail', true)
            this.model.service_ticket_info =
                this.formViewDatas.serviceTickets.find(
                    el => el.service_ticket_id == value
                )
            this.getFormViewModel({
                index: 'serviceTicketDetail',
                params: {
                    fnName: 'serviceTicketDetail',
                    service_ticket_id: value
                }
            })
                .then(response => {
                    this.model.service_ticket =
                        response.data.serviceTicketDetail
                })
                .finally(() => this.$emit('loadingDetail', false))
        }
    }
}
</script>
